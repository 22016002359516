import { CardNavigation } from '@/components'
import { FlowStep } from '@/app/components'
import { Question } from '../../../components'
import GoogleMapsSearchInput from '../../google-maps/GoogleMapsSearchInput'
import data from './resources/data.json'
import './styles.scss'
import { GuardableAction } from '@/app/components/FlowStep'

export interface Props {
  onGooglePlaceIdChange: (googlePlaceId: string) => void
  nextAction: GuardableAction
  onClose?: () => void
}

export default function AddAddressFlowStep(props: Props) {
  const { nextAction: onNext } = props
  return (
    <FlowStep id="add-address-flow" onNext={onNext}>
      <FlowStep.Content>
        <CardNavigation
          id="add-address-card-navigation"
          onCloseClick={props.onClose}
        />
        <Question title={data.question} subtitle={data.subtitle}>
          <GoogleMapsSearchInput
            onChange={props.onGooglePlaceIdChange}
            label={data.searchLabel}
          />
        </Question>
      </FlowStep.Content>
    </FlowStep>
  )
}
