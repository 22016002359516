import UtilityOnboardingRoot from './UtilityOnboarding'
import ErrorBoundary, {
  LocationTag,
} from '@/authenticated/components/ErrorBoundary'

export default function UtilityOnboarding() {
  return (
    <ErrorBoundary
      location={LocationTag.UtilityOnboarding}
      functionalityDescription="Utility Connection"
      severity="critical"
    >
      <UtilityOnboardingRoot />
    </ErrorBoundary>
  )
}
