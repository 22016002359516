import React, { useCallback } from 'react'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import parse from 'autosuggest-highlight/parse'
import throttle from 'lodash/throttle'
import debounce from '@/utils/limit/debounce'

// This came from: https://material-ui.com/components/autocomplete/
const autocompleteService: any = { current: null }

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
}))

type AutocompletePrediction = {
  description: string
  place_id: string
  structured_formatting: {
    main_text: string
    secondary_text: string
    main_text_matched_substrings: {
      length: number
      offset: number
    }[]
  }
}

export interface Props {
  onChange: (placeId: string) => void
  label?: string
}

export default function GoogleMapsSearch({
  onChange,
  label = 'Add a location',
}: Props) {
  const DEBOUNCE = 400
  const classes = useStyles()
  const [inputValue, setInputValue] = React.useState('')
  const [options, setOptions] = React.useState<AutocompletePrediction[]>([])
  const loaded = React.useRef(false)

  loaded.current = true

  const debouncedSave = useCallback(
    debounce((nextValue) => setInputValue(nextValue), DEBOUNCE),
    [],
  )

  const handleTextFieldChange = (event: any) => {
    /* @ts-expect-error 'any' is not assignable to type 'never' */
    debouncedSave(event.target.value)
  }

  const fetch = React.useMemo(
    () =>
      throttle((input, callback) => {
        autocompleteService.current.getPlacePredictions(input, callback)
      }, DEBOUNCE),
    [],
  )

  React.useEffect(() => {
    let active = true

    const google = (window as any).google

    if (!autocompleteService.current && google) {
      autocompleteService.current = new google.maps.places.AutocompleteService()
    }
    if (!autocompleteService.current) {
      return undefined
    }

    if (inputValue === '') {
      setOptions([])
      return undefined
    }

    fetch(
      { input: inputValue, types: ['address'] },
      (results: AutocompletePrediction[] | null) => {
        if (active) {
          setOptions(results || [])
        }
      },
    )

    return () => {
      active = false
    }
  }, [inputValue, fetch])

  const handleChange = (event: any, value: any) => {
    onChange(value?.place_id)
  }

  return (
    <Autocomplete
      id="google-map-demo"
      data-testing-id="address-input"
      getOptionLabel={(option) =>
        typeof option === 'string' ? option : option.description
      }
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      onChange={handleChange}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          fullWidth
          required
          onChange={handleTextFieldChange}
        />
      )}
      renderOption={(option) => {
        const matches =
          option.structured_formatting.main_text_matched_substrings
        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match) => [match.offset, match.offset + match.length]),
        )

        return (
          <Grid container alignItems="center">
            <Grid item>
              <LocationOnIcon className={classes.icon} />
            </Grid>
            <Grid item xs>
              {parts.map((part, index) => (
                <span
                  key={index}
                  data-place-id={option.place_id}
                  style={{ fontWeight: part.highlight ? 700 : 400 }}
                >
                  {part.text}
                </span>
              ))}

              <Typography variant="body2" color="textSecondary">
                {option.structured_formatting.secondary_text}
              </Typography>
            </Grid>
          </Grid>
        )
      }}
    />
  )
}
