import UtilitySearchInputRoot, {
  Props as ComponentProps,
} from './UtilitySearchInput'
import useViewModel from './useViewModel'
import { ViewModelComponentProps } from '../../../../utils/typeUtilities'

type Props = ViewModelComponentProps<ComponentProps, typeof useViewModel>

export default function UtilitySearchInput(props: Props) {
  const viewModel = useViewModel()

  return <UtilitySearchInputRoot {...props} {...viewModel} />
}
