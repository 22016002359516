import './styles.scss'
import ConnectDeviceInformationCard from '@/authenticated/components/ConnectDeviceInformationCard'
import data from './resources/data.json'
import connectUtilityAnimation from '@/app/img/connect-utility-lottie.json'
import Lottie from 'lottie-react'

export interface Props {
  onClick: () => void
  onBackClick?: () => void
}

const ConnectUtilityAnimation = (
  <Lottie animationData={connectUtilityAnimation} loop />
)

export default function ConnectUtilityInformationCard(props: Props) {
  return (
    <ConnectDeviceInformationCard
      onboarding
      media={ConnectUtilityAnimation}
      id="connect-utility-information-card"
      headerlessOnDesktop={false}
      title={data.title}
      subtitle={data.subtitle}
      informationCards={data.informationCards}
      onClick={props.onClick}
      onBackClick={props.onBackClick}
    />
  )
}
