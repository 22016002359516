import { useMemo, useRef, useState } from 'react'
import { TextField } from '@material-ui/core'
import { Autocomplete, createFilterOptions } from '@material-ui/lab'
import { Utility, UtilitySearchResult } from '../../../../types/utility'
import throttle from 'lodash/throttle'
import { useDispatch } from 'react-redux'
import { clearAllUtilities, getAllUtilities } from '@/actions/utilities'

const filter = createFilterOptions({ limit: 10 })

type UtilityOption = Partial<UtilitySearchResult> & { label?: string }

export interface Props {
  localUtilityOptions: Partial<Utility>[]
  allUtilityOptions: Partial<UtilitySearchResult>[]
  selectedUtility?: Partial<UtilitySearchResult> | null
  label?: string
  onChange: (utility: Partial<UtilitySearchResult> | null) => void
}

export const MIN_SEARCH_LENGTH = 2

const UtilitySearchbar = ({
  localUtilityOptions = [],
  allUtilityOptions = [],
  selectedUtility = null,
  label = 'Search for a utility',
  onChange,
}: Props) => {
  const dispatch = useDispatch()

  const getNewUtilities = useRef(
    throttle((searchValue: string) => {
      dispatch(getAllUtilities(searchValue))
    }, 300),
  )
  const initialUtility: UtilityOption | null =
    selectedUtility ?? localUtilityOptions[0] ?? null

  const [value, setValue] = useState(initialUtility)
  const [valueLongEnough, setValueLongEnough] = useState(false)
  const localOptions =
    localUtilityOptions?.map((x) => ({ ...x, label: 'Most Likely' })) || []
  const allOptions =
    allUtilityOptions?.map((x) => ({ ...x, label: 'All' })) || []
  const options = useMemo(() => {
    const options: UtilityOption[] = [...localOptions, ...allOptions]
    if (initialUtility && !options.find((x) => x.id === initialUtility.id)) {
      options.push(initialUtility)
    }
    // Dedup options by id
    const dedupedOptions = options.reduce((acc: UtilityOption[], curr) => {
      if (!curr.id || acc.find((x) => x.id === curr.id)) {
        return acc
      }
      return [...acc, curr]
    }, [])

    return dedupedOptions
  }, [localOptions, allOptions, initialUtility])

  const nameHashMap: { [key: string]: Partial<UtilitySearchResult> } =
    options.reduce((dict, el, index) => {
      if (!el.name || !options[index]) {
        return dict
      }
      dict[el.name] = options[index]
      return dict
    }, {} as { [key: string]: UtilityOption })

  function getUtilityByName(name: string): UtilityOption | null {
    return name in nameHashMap ? nameHashMap[name] : null
  }

  function changeUtilitySelection(utility: UtilityOption) {
    onChange(utility)
    setValue(utility)
  }

  function changeUtilityEntry(utilityName: string) {
    const utility = getUtilityByName(utilityName)
    const trimmedUtilityName = utilityName.trim()
    onChange(utility)
    setValueLongEnough(trimmedUtilityName.length >= MIN_SEARCH_LENGTH)

    // prevent all utilities from being requested
    if (trimmedUtilityName.length < MIN_SEARCH_LENGTH)
      dispatch(clearAllUtilities())
    else getNewUtilities.current(trimmedUtilityName)
  }

  return (
    <Autocomplete
      id="utility-select-autocomplete"
      value={value}
      onInputChange={(_event, newValue) => {
        changeUtilityEntry(newValue)
      }}
      onChange={(_event, newValue: any) => {
        if (typeof newValue === 'string') {
          changeUtilitySelection({ name: newValue })
        } else if (newValue && newValue.inputValue) {
          // Create a new value from the user input
          changeUtilitySelection({ name: newValue.inputValue })
        } else {
          changeUtilitySelection(newValue)
        }
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params)
        // Suggest the creation of a new value
        if (params.inputValue !== '') {
          filtered.push({
            inputValue: params.inputValue,
            name: `Add "${params.inputValue}"`,
          })
        }
        return filtered
      }}
      options={valueLongEnough ? options : []}
      getOptionLabel={(option: any) => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
          return option
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue
        }

        if (!option.name) {
          return ''
        }
        // Regular option
        return option.name
      }}
      renderOption={(option) => option.name}
      renderInput={(params) => (
        <TextField {...params} label={label} fullWidth required />
      )}
      freeSolo
      selectOnFocus
    />
  )
}

export default UtilitySearchbar
