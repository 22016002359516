import { useAppSelector } from '../../../../hooks'
import { Utility, UtilitySearchResult } from '../../../../types/utility'
import mockUtilities from '../../../../types/utility/mock'
import useMockableViewModel from '../../../../hooks/useMockableViewModel'

function useViewModel() {
  const localUtilityOptions: Partial<Utility>[] = useAppSelector(
    (state) => state.homes.utilities ?? [],
  )
  const allUtilityOptions: Partial<UtilitySearchResult>[] = useAppSelector(
    (state) => state.utilities.allUtilities ?? [],
  )

  return {
    localUtilityOptions,
    allUtilityOptions,
  }
}

function useMockViewModel() {
  const utilities = mockUtilities as Partial<Utility>[]
  return {
    localUtilityOptions: utilities.slice(0, 2),
    allUtilityOptions: utilities as Partial<UtilitySearchResult>[],
  }
}

export default useMockableViewModel({ useViewModel, useMockViewModel })
