import { FlowStep } from '@/app/components'
import {
  Button,
  CardAction,
  CardActions,
  Grid,
  LoadingAnimation,
  Text,
} from '@/components'
import { Identifiable } from '@/types'
import formatClasses from '@/utils/classes/formatClasses'
import data from './resources/data.json'
import check from '@/app/img/check.svg'
import frown from '@/app/img/frown.svg'
import waiting from '@/app/img/waiting.svg'
import './styles.scss'

type ConnectionStatusMode = 'connecting' | 'connected' | 'failed'

interface Props extends Identifiable {
  onClick: () => void
  statusText: { [key in ConnectionStatusMode]: string }
  mode: ConnectionStatusMode
}

const ConnectionStatusImageSrc = {
  connecting: waiting,
  connected: check,
  failed: frown,
}

const ConnectionStatus = (props: { mode: ConnectionStatusMode }) => {
  if (props.mode === 'connecting') {
    return <LoadingAnimation type="falling" />
  } else {
    return <img alt={props.mode} src={ConnectionStatusImageSrc[props.mode]} />
  }
}

export default function OnboardingStatusFlowStep(props: Props) {
  const classes = {
    content: formatClasses(['onboarding-status-flow-content']),
    contentText: formatClasses(['content-text']),
  }

  const cancellable = props.mode === 'connecting'

  return (
    <FlowStep id={`${props.id}-onboarding-status-flow`}>
      <FlowStep.Content>
        <Grid
          className={classes.content}
          flow="row"
          gap="24px"
          placeItems="center"
          placeContent="center"
        >
          <ConnectionStatus mode={props.mode} />
          <Text className={classes.contentText} variant="h3" align="center">
            {props.statusText[props.mode]}
          </Text>
        </Grid>
      </FlowStep.Content>
      <FlowStep.Actions>
        <CardActions>
          <CardAction type="primary">
            <Button
              variant={cancellable ? 'secondary' : 'primary'}
              id="select-utility-plan-card-select-button"
              onClick={props.onClick}
            >
              {data.buttonText[props.mode]}
            </Button>
          </CardAction>
        </CardActions>
      </FlowStep.Actions>
    </FlowStep>
  )
}
