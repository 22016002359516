import { useState } from 'react'
import { useAppSelector, useAppDispatch } from '../../../../hooks'
import { UtilitySearchResult } from '../../../../types/utility'
import { RequestStatus } from '../../../../request/types'
import {
  selectUtility,
  createUtility,
  getUtilities,
} from '../../../../actions/utilities'
import useMockableViewModel from '../../../../hooks/useMockableViewModel'
import { utilityProgramCollection } from '../../../../reducers/utilityPrograms'

type Props = {
  onNext?: () => void
}

function useViewModel(props: Props) {
  // hasSelectedUtility is used to determine if the user has selected a utility
  // since seeing this view.
  const [hasSelectedUtility, setHasSelectedUtility] = useState(false)
  // startedSelectingUtility is used to determine the dispatch started calling
  // selectUtility or createUtility
  const [startedSelectingUtility, setStartedSelectingUtility] = useState(false)
  const dispatch = useAppDispatch()
  const initialSelectedUtility = useAppSelector(
    (state) => state.utilities.selectedUtility,
  )
  const [selectedUtility, setSelectedUtility] =
    useState<Partial<UtilitySearchResult> | null>(initialSelectedUtility)

  const submitStatus = useAppSelector((state) => {
    const utilityErrors = state.utilities.errors
    const utilityProgramStatus =
      utilityProgramCollection.selectors.status(state)
    const utilityProgramLoading = utilityProgramStatus === RequestStatus.Loading
    const selectingUtilityLoading =
      startedSelectingUtility && !hasSelectedUtility
    const utilityProgramHasErrors =
      utilityProgramStatus === RequestStatus.Failed

    if (selectingUtilityLoading || utilityProgramLoading) {
      return RequestStatus.Loading
    }

    if (Object.keys(utilityErrors).length > 0 || utilityProgramHasErrors) {
      return RequestStatus.Failed
    }

    if (hasSelectedUtility) {
      return RequestStatus.Succeeded
    }

    return RequestStatus.Idle
  })

  const onSubmit = () => {
    if (!selectedUtility) {
      return
    }

    setStartedSelectingUtility(true)

    let submitPromise: Promise<void>

    if ('id' in selectedUtility) {
      submitPromise = dispatch(
        selectUtility({ utility_id: selectedUtility.id }),
      )
        .then(() => dispatch(getUtilities()))
        // fetch utility programs after utility, because they depend on utility
        .then(() => dispatch(utilityProgramCollection.actions.fetch()))
    } else {
      submitPromise = dispatch(createUtility({ name: selectedUtility.name }))
    }

    submitPromise.then(() => {
      setTimeout(() => {
        // creates time for success state to show on button
        props.onNext?.()
      }, 750)
      setHasSelectedUtility(true)
    })
  }

  const onChange = setSelectedUtility

  return {
    onSubmit,
    onChange,
    selectedUtility,
    status: submitStatus,
    disabled: !selectedUtility,
  }
}

function useMockViewModel(props: Props) {
  const [selectedUtility, setSelectedUtility] =
    useState<Partial<UtilitySearchResult> | null>(null)
  return {
    onSubmit() {
      alert(`Submit Utility with id: ${selectedUtility?.id}`)
      props.onNext?.()
    },
    onChange: setSelectedUtility,
    selectedUtility,
    status: RequestStatus.Idle,
    disabled: false,
  }
}

export default useMockableViewModel({ useViewModel, useMockViewModel })
