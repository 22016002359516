import {
  CardProps,
  CardAction,
  CardActions,
  CardNavigation,
} from '@/components'
import { FlowStep, Question, StatusButton } from '../../../components'
import UtilitySearchInput from '../../utilities/UtilitySearchInput'
import { UtilitySearchResult } from '@/types/utility'
import { RequestStatus } from '@/request/types'
import data from './resources/data.json'

export interface Props extends CardProps {
  selectedUtility: Partial<UtilitySearchResult> | null
  onChange: (utility: Partial<UtilitySearchResult> | null) => void
  onSubmit: () => void
  onClose?: () => void
  status: RequestStatus
  disabled?: boolean
}

export default function SelectUtilityFlowStep({
  className,
  status,
  ...props
}: Props) {
  return (
    <FlowStep id="select-utlity-flow">
      <FlowStep.Content>
        <CardNavigation
          id="select-utility-card-navigation"
          onCloseClick={props.onClose}
        />
        <Question title={data.question} subtitle={data.subtitle}>
          <UtilitySearchInput
            selectedUtility={props.selectedUtility}
            onChange={props.onChange}
            label={data.searchLabel}
          />
        </Question>
      </FlowStep.Content>

      <FlowStep.Actions>
        <CardActions>
          <CardAction type="primary">
            <StatusButton
              variant="primary"
              disabled={props.disabled}
              onClick={props.onSubmit}
              status={status}
              id="select-utility-card-select-button"
            >
              Next
            </StatusButton>
          </CardAction>
        </CardActions>
      </FlowStep.Actions>
    </FlowStep>
  )
}
