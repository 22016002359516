import { addHome } from '@/actions/homes'
import { getPlans } from '@/actions/plans'
import { getCurrentUser } from '@/actions/user'
import { getUtilities } from '@/actions/utilities'
import { GuardableAction } from '@/app/components/FlowStep'
import { useAppDispatch, useAppSelector } from '@/hooks'
import useMockableViewModel from '@/hooks/useMockableViewModel'
import { useMemo, useState } from 'react'

interface Props {
  onNext: () => void
}

function useViewModel(props: Props) {
  const dispatch = useAppDispatch()
  const [googlePlaceId, setGooglePlaceId] = useState<string | undefined | null>(
    undefined,
  )
  const homesAreLoading = useAppSelector((state) => state.homes.isLoading)

  const nextAction: GuardableAction = useMemo(
    () => ({
      action: () => {
        dispatch(addHome({ google_place_id: googlePlaceId })).then(async () => {
          // Update caches for utilities and plans
          await Promise.all([
            dispatch(getUtilities()),
            dispatch(getPlans()),
            dispatch(getCurrentUser()),
          ])
          props.onNext()
        })
      },
      precondition: Boolean(googlePlaceId) && !homesAreLoading,
    }),
    [googlePlaceId, homesAreLoading],
  )

  const onGooglePlaceIdChange = setGooglePlaceId
  return { googlePlaceId, onGooglePlaceIdChange, nextAction }
}

function useMockViewModel(props: Props) {
  return {
    googlePlaceId: undefined as string | undefined | null,
    onGooglePlaceIdChange: (googlePlaceId: string) =>
      console.log(googlePlaceId),
    nextAction: (() => {}) as GuardableAction,
  }
}

export default useMockableViewModel({ useViewModel, useMockViewModel })
