import SelectUtilityFlowStepRoot, {
  Props as ComponentProps,
} from './SelectUtilityFlowStep'
import useViewModel from './useViewModel'
import { ViewModelComponentProps } from '../../../../utils/typeUtilities'

export type Props = ViewModelComponentProps<ComponentProps, typeof useViewModel>

export default function SelectUtilityFlowStep(props: Props) {
  const viewModel = useViewModel(props)
  return <SelectUtilityFlowStepRoot {...viewModel} {...props} />
}
