import { ViewModelComponentProps } from '@/utils/typeUtilities'
import AddAddressFlowStepRoot, {
  Props as CommponentProps,
} from './AddAddressFlowStep'
import useViewModel from './useViewModel'

export type Props = ViewModelComponentProps<
  CommponentProps,
  typeof useViewModel
>

export default function AddAddressFlowStep(props: Props) {
  const viewModel = useViewModel(props)
  return <AddAddressFlowStepRoot {...viewModel} {...props} />
}
