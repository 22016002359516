import ConnectUtilityInformationCard from 'features/utilities/ConnectUtilityInformationCard'
import AddAddressFlowStep from '../AddAddressFlowStep'
import SelectUtilityFlowStep from '../SelectUtilityFlowStep'
import AddUtilityPlanFlow from '../AddUtilityPlanFlow'
import OnboardingStatusFlowStep from '../../onboarding/OnboardingStatusFlowStep'
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom'
import { useAppSelector, useUrlSearchParam } from '@/hooks'
import { useEffect } from 'react'
import { useNavigation } from '@/app/hooks'
import { selectUserHasMadePlanSelection } from '@/selectors'
import { FullPageCard, NavigationPage } from '@/app/components'
import XButton from '@/components/XButton/XButton'

function ConnectUtilityInformationStep() {
  const navigation = useNavigation()
  const selectedHome = useAppSelector((state) => state.homes.selectedHome)

  const nextStep = () => {
    if (selectedHome) {
      navigation.push('/connect-utility/select')
    } else {
      navigation.push('/connect-utility/address')
    }
  }

  return (
    <FullPageCard>
      <ConnectUtilityInformationCard onClick={nextStep} />
    </FullPageCard>
  )
}

function AddAddressStep() {
  const navigation = useNavigation()

  const nextStep = () => {
    navigation.push('/connect-utility/select')
  }

  return <AddAddressFlowStep onNext={nextStep} />
}

function SelectUtilityStep() {
  const navigation = useNavigation()

  const nextStep = () => {
    navigation.push(`/utility-program-enrollment?from_connect_utility=true`)
  }

  return <SelectUtilityFlowStep onNext={nextStep} />
}

function AddPlanStep() {
  const navigation = useNavigation()

  const nextStep = () => {
    navigation.push('/connect-utility/status')
  }

  return <AddUtilityPlanFlow onComplete={nextStep} />
}

function UtilityOnboardingStatusStep() {
  const navigation = useNavigation()
  const errorsPresent = useAppSelector((state) =>
    [
      Object.keys(state.plans.errors).length,
      Object.keys(state.utilities.errors).length,
      Object.keys(state.manualPlans.errors).length,
      Object.keys(state.homes.errors).length,
      Object.keys(state.user.errors).length,
    ].some(Boolean),
  )

  const onClick = () => {
    navigation.push('/app')
  }

  const status = errorsPresent ? 'failed' : 'connected'
  const statusText = {
    connected: 'Great! You successfully connected your utility',
    failed: 'Oh no! Something went wrong. Please go back and try again',
    connecting: "Hold tight...we're finishing up your connection",
  }

  return (
    <OnboardingStatusFlowStep
      id="utility-onboarding-status"
      mode={status}
      onClick={onClick}
      statusText={statusText}
    />
  )
}

export default function UtilityOnboarding() {
  const history = useHistory()
  const navigation = useNavigation()
  const fromUPE = Boolean(useUrlSearchParam('from_UPE'))
  const { path, params } = useRouteMatch<{
    '0': `/${(typeof routes)[number]}`
  }>()
  const route = params[0].slice(1).split('/')[0] as (typeof routes)[number]
  const routes = ['information', 'address', 'select', 'plan', 'status'] as const

  const initialStep = useAppSelector((state) => {
    const hasHome = state.homes.selectedHome
    const hasSelectedUtility = state.utilities.selectedUtility
    const hasUtilityProviderLink = hasSelectedUtility?.has_api_provider_link
    const hasMadeGridSupportSelection =
      state.user.user?.profile.has_made_grid_support_selection
    const hasPlan = selectUserHasMadePlanSelection(state)

    if (!hasHome) {
      return 'information'
    } else if (!hasSelectedUtility) {
      return 'select'
    } else if (hasUtilityProviderLink && !hasMadeGridSupportSelection) {
      return 'utility_program_enrollment'
    } else if (!hasPlan) {
      return 'plan'
    } else {
      return 'status'
    }
  })

  useEffect(() => {
    if (!routes.includes(route)) {
      // redirect to initial step
      if (initialStep === 'utility_program_enrollment') {
        history.replace('utility-program-enrollment?from_connect_utility=true')
      } else {
        history.replace(`/connect-utility/${initialStep}`)
      }
    }
  }, [])

  function onCloseClick() {
    navigation.push('/app')
  }

  function onBackOverride() {
    if (route === 'plan' && fromUPE) {
      navigation.go(-2)
    } else {
      navigation.goBack()
    }
  }

  return (
    <NavigationPage id="utility-onboarding" backOverride={onBackOverride}>
      <NavigationPage.SingleCardContent>
        <div className="flex w-full justify-end">
          <XButton onClick={onCloseClick} />
        </div>
        <Switch>
          <Route
            exact
            path={`${path}/information`}
            component={ConnectUtilityInformationStep}
          />
          <Route exact path={`${path}/address`} component={AddAddressStep} />
          <Route exact path={`${path}/select`} component={SelectUtilityStep} />
          <Route
            exact
            path={[`${path}/plan`, `${path}/plan*`]}
            component={AddPlanStep}
          />
          <Route
            exact
            path={`${path}/status`}
            component={UtilityOnboardingStatusStep}
          />
        </Switch>
      </NavigationPage.SingleCardContent>
    </NavigationPage>
  )
}
